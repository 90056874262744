* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
}

h1, h2, h3, h4, h5, h6, th, label {
  line-height: 1.25;
}

h1, h2, h3, h4, h5, h6 {
  color: $apjRed;
}

h1 {
  font-size: 2em;
}

p, li, td {
  line-height: 1.5;
}

img {
  max-width: 100%;
}

a {
  color: $apjRed;
}

a:hover {
  color: $darkRed;
}

address {
  font-style: normal;
}

th, td {
  padding: .5rem;
  text-align: left;
}

label, button {
  font-size: 1.25em;
}

label {
  font-weight: bold;
}

input, textarea {
  font-size: inherit;
  font-family: inherit;
}

input + div, textarea + div {
  margin-top: 1em;
}

iframe {
  width: 100%;
  min-height: 32.5rem;
  border: none;
}

iframe[src*="quantumtuning"] {
  height: 95px;
  min-height: 0;
  max-width: 100%;
}
