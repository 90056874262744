//
// Type scale
//

.f4 {
  font-size: 1.25rem;
}

@media screen and (min-width: 30em) {
  .f3-ns {
    font-size: 1.5rem;
  }
}


//
// Font weight
//

.b {
  font-weight: bold;
}


//
// Font style
//

.i {
  font-style: italic;
}


//
// Text alignment
//

.tc {
  text-align: center;
}


//
// Text decoration
//

.underline-hover:hover, .underline-hover:focus {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

@media screen and (min-width: 48em) {
  .no-underline-m, .no-underline-m:hover, .no-underline-m:focus {
    text-decoration: none;
  }
}


//
// Font families
//

.serif {
  font-family: times, georgia, serif;
}
