//
// Colors
//

.white {
  color: #fff;
}

.hover-white:hover {
  color: #fff;
}

.hover-black:hover {
  color: #000;
}

.red {
  color: $apjRed;
}


//
// Background colors
//

.bg-black {
  background-color: #000;
}

.bg-light-grey {
  background-color: $lightGrey;
}

.bg-white {
  background-color: #fff;
}

.bg-red, .hover-bg-red:hover {
  background-color: $apjRed;
}

.hover-bg-dark-red:hover {
  background-color: $darkRed;
}

@media screen and (min-width: 48em) {
  .hover-bg-red-m:hover {
    background-color: $apjRed;
  }
}


//
// Backgrounds
//

.cover {
  background-size: cover
}

.no-repeat {
  background-repeat: no-repeat;
}

.center-bg {
  background-position: center;
}


//
// Borders
//

.bt {
  border-top-style: solid;
  border-top-width: 1px;
}

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bn {
  border-style: none;
  border-width: 0;
}

.bw1 {
  border-width: .125rem;
}

.bw3 {
  border-width: .5rem;
}

.b--transparent {
  border-color: transparent;
}

.b--black {
  border-color: #000;
}

.b--silver {
  border-color: $silver;
}

.b--red {
  border-color: $apjRed;
}


//
// Cursors
//

.pointer:hover {
  cursor: pointer;
}
