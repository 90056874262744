//
// Links
//

.link {
  text-decoration: none;
  transition: color .15s, background-color .15s;
}


//
// Lists
//

.list {
  list-style: none;
}
