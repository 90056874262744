//
// Display
//

.db {
  display: block;
}

.dib {
  display: inline-block;
}


//
// Widths
//

.w-100 {
  width: 100%;
}

.w-two-thirds {
  width: calc(100% / 1.5);
}

@media screen and (min-width: 48em) {
  .w-100-m {
    width: 100%;
  }
}

@media screen and (min-width: 48em) and (max-width: 60em) {
  .w-half-m {
    width: 50%;
  }
}

@media screen and (min-width: 60em) {
  .w-half-l {
    width: 50%;
  }

  .w-third-l {
    width: calc(100% / 3);
  }
}


//
// Spacing
//

.pa0 {
  padding: 0;
}

.pa1 {
  padding: .25rem;
}

.pa3 {
  padding: 1rem;
}

.pv2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pv3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pv4, .pt4 {
  padding-top: 2rem;
}

.pv4, .pb4 {
  padding-bottom: 2rem;
}

.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: .25rem;
}

.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pl0 {
  padding-left: 0;
}

.mt-auto {
  margin-top: auto;
}

.mt0, .mv0 {
  margin-top: 0;
}

.mb1 {
  margin-bottom: .25rem;
}

.mb3 {
  margin-bottom: 1rem;
}

.mv0 {
  margin-bottom: 0;
}


//
// Flexbox
//

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.items-start {
  align-items: flex-start;
}


//
// Containers
//

.container {
  width: 88%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
}

.container-small {
  max-width: 48rem;
}
